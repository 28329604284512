.Universe{
  background-color: rgb(24, 24, 24);
  /* height: 100vh; */
  color: azure;
}
.back-list-btn{
  background-color: transparent;
  border-radius: 10px;
  border: 2px #3041ff solid;
  color: azure;
  padding: 8px;
  margin-left: 10px;
  margin-top: 10px;
}