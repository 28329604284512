.listcard{
    background-color: azure;
    color: black;
    display: inline-block;
    border-radius: 10px;
    margin: 10px;
    padding: 10px;
    width: 90%;
}
.back-list-btn{
    background-color: transparent;
    border-radius: 10px;
    border: 2px #3041ff solid;
    color: azure;
    padding: 8px;
    margin-left: 10px;
    margin-top: 10px;
}
.list-view{
    background-color: rgb(206, 206, 206);
    margin-left: 10px;
    color: black;
    padding: 10px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    width: 100%;
}
.list-view h1{
    margin-left: 5px;
}
.search{
    margin-left: 10px;
    border-radius: 25px;
    font-size: 16px;
    padding: 5px;
}
.list-view input{
    border-radius: 10px;
    margin: 10px;
    border: 2px black solid;
    padding: 5px;
    width: 98%;
}
.userbtn{
    /* margin-left: 30%; */
    /* margin-right: 30%; */
    border-radius: 10px;
    border: 2px #3041ff solid;
    background-color: #3041ff;
    padding: 10px;
    color: white;
    width: 15%;
    margin-left: 15px;
    margin-top: 10px;
    margin-bottom: 10px;

}
.deletebtn{
    /* margin-left: 30%; */
    /* margin-right: 30%; */
    border-radius: 10px;
    border: 2px #ff3030 solid;
    background-color: #ff3030;
    padding: 10px;
    color: white;
    width: 15%;
    margin-left: 15px;
    margin-top: 10px;
    margin-bottom: 10px;

}